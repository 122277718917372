import { getIn } from "formik";
import type { TFunction } from "i18next";

import { ReactLazyLoading } from "../../containers/ReactLoading";
import { saveTask as saveAbsTask } from "../../customers/abs/Tasks/saveTask";
import { saveTask as saveCommlaceTask } from "../../customers/commlace/Tasks/saveTask";
import { saveTask as saveMphoneTask } from "../../customers/mphone/vertrieb/Tasks/Form/saveTask";
import { saveTask as saveProxytelTask } from "../../customers/proxytel/vertrieb/Tasks/Form/saveTask";
import { saveTask as saveReputativTask } from "../../customers/reputativ/Tasks/saveTask";
import { setContactField } from "../../customers/sevenit/contacts/show/projects/Upselling621HistoryCallback";
import { saveTask as saveUNNTask } from "../../customers/unn/CustomerContacts/Form/saveTask";
import { SessionInterface } from "../../store/sessionReducer";
import {
  ContactInterface,
  ContactStatusInterface,
  HistoryInterface,
  Nilable,
  Nullable,
  TaskInterface,
  TodoStatusType,
} from "../../types";
import { QuestionnaireComponentType } from "./show/Questionnaire";

const SugContact = ReactLazyLoading(() => import("../../customers/sug/contacts/show/projects"));

type ComponentType =
  | "list"
  | "new"
  | "show"
  | "status-modal/addfields"
  | "agenda"
  | "project"
  | "histories/todos"
  | "histories/customerContacts"
  | "histories/mails"
  | "histories/callback"
  | "searchbar"
  | "export"
  | "saveTask";

type ComponentsType = {
  [key: string]: {
    [key: string]: {
      list?: React.ComponentType;
      new?: React.ComponentType;
      show?: React.ComponentType;
      "status-modal/addfields"?: React.ComponentType;
      agenda?: React.ComponentType;
      project?: React.ComponentType;
      "histories/todos"?: React.ComponentType;
      "histories/customerContacts"?: React.ComponentType;
      "histories/mails"?: React.ComponentType;

      "histories/callback"?: (
        session: SessionInterface,
        contact: ContactInterface,
        history: HistoryInterface,
      ) => Promise<void>;

      searchbar?: React.ComponentType;
      export?: React.ComponentType;

      saveTask?: (
        session: SessionInterface,
        existingTask: Nilable<TaskInterface>,
        states: ContactStatusInterface[],
        values: any,
        t: TFunction,
      ) => Promise<TaskInterface | undefined>;
    };
  };
};

const COMPONENTS: ComponentsType = {
  "sug-ag": {
    default: {
      project: SugContact,
      searchbar: ReactLazyLoading(() => import("../../customers/sug/contacts/SearchBar")),
      agenda: ReactLazyLoading(() => import("../../customers/sug/contacts/Agenda")),
    },
  },
  "sug-gmbh": {
    default: {
      project: SugContact,
      searchbar: ReactLazyLoading(() => import("../../customers/sug/contacts/SearchBar")),
      agenda: ReactLazyLoading(() => import("../../customers/sug/contacts/Agenda")),
    },
  },

  "sevenit-gmbh": {
    default: {
      list: ReactLazyLoading(() => import("../../customers/sevenit/contacts/List")),
      project: ReactLazyLoading(() => import("../../customers/sevenit/contacts/show/projects/Default")),
    },
    sales: {
      project: ReactLazyLoading(() => import("../../customers/sevenit/contacts/show/projects/Sales")),
    },
    "upselling-6-2021": {
      project: ReactLazyLoading(() => import("../../customers/sevenit/contacts/show/projects/Upselling621")),
      "histories/callback": setContactField,
    },
  },

  metacrew: {
    foodist: {
      project: ReactLazyLoading(() => import("../../customers/metacrew/contacts/show/projects/Foodist")),
    },
  },

  chrono24: {
    "2021-11": {
      list: ReactLazyLoading(() => import("../../customers/chrono24/contacts/List")),
      project: ReactLazyLoading(() => import("../../customers/chrono24/contacts/show/projects/Nov2021")),
      searchbar: ReactLazyLoading(() => import("../../customers/chrono24/contacts/ChronoSearchBar")),
    },
  },

  "lr-health-beauty": {
    default: {
      project: ReactLazyLoading(() => import("../../customers/lr-health-beauty/contacts/show/projects/Service")),
      "histories/todos": ReactLazyLoading(
        () => import("../../customers/lr-health-beauty/contacts/show/projects/Todos"),
      ),
    },
    "outbound-exit": {
      project: ReactLazyLoading(() => import("../../customers/lr-health-beauty/contacts/show/projects/Service")),
    },
  },

  "lr-france": {
    frankreich: {
      project: ReactLazyLoading(() => import("../../customers/lr-health-beauty/contacts/show/projects/Service")),
      "histories/todos": ReactLazyLoading(
        () => import("../../customers/lr-health-beauty/contacts/show/projects/Todos"),
      ),
    },

    service: {
      project: ReactLazyLoading(() => import("../../customers/lr-france/contacts/show/projects/ServiceFrance")),
      list: ReactLazyLoading(() => import("../../customers/lr-france/contacts/List")),
    },
  },

  euromaster: {
    default: {
      list: ReactLazyLoading(() => import("../../customers/euromaster/contacts/List")),
    },
  },

  friendsjob: {
    partnergewinnung: {
      export: ReactLazyLoading(() => import("../../customers/friendsjob/contacts/Export")),
      project: ReactLazyLoading(() => import("../../customers/friendsjob/contacts/show/projects/Default")),
      searchbar: ReactLazyLoading(() => import("../../customers/friendsjob/contacts/Searchbar")),
    },
  },

  unn: {
    vertrieb: {
      agenda: ReactLazyLoading(() => import("../../customers/unn/contacts/Agenda")),
      saveTask: saveUNNTask,
      searchbar: ReactLazyLoading(() => import("../../customers/unn/contacts/Searchbar")),
    },
  },

  proxytel: {
    vertrieb: {
      saveTask: saveProxytelTask,
      agenda: ReactLazyLoading(() => import("../../customers/proxytel/contacts/Agenda")),
    },
  },

  mphone: {
    vertrieb: {
      saveTask: saveMphoneTask,
      agenda: ReactLazyLoading(() => import("../../customers/mphone/contacts/Agenda")),
    },
  },

  commlace: {
    kundenservice: {
      project: ReactLazyLoading(() => import("../../customers/commlace/Contacts/Show")),
      saveTask: saveCommlaceTask,
    },
  },

  termitel: {
    bewerbermanagement: {
      project: ReactLazyLoading(() => import("../../customers/termitel/contacts/show/projects/Bewerbermanagement")),
    },
    vertrieb: {
      project: ReactLazyLoading(() => import("../../customers/termitel/contacts/show/projects/Vertrieb")),
    },
  },

  "abs-rz": {
    vertrieb: {
      saveTask: saveAbsTask,
      agenda: ReactLazyLoading(() => import("../../customers/abs/Contacts/Agenda")),
    },
  },

  cityweb: {
    default: {
      project: ReactLazyLoading(() => import("../../customers/cityweb/contacts/show")),
    },
  },

  reputativ: {
    default: {
      saveTask: saveReputativTask,
      project: ReactLazyLoading(() => import("../../customers/reputativ/contacts/show")),
      agenda: ReactLazyLoading(() => import("../../customers/reputativ/contacts/Agenda")),
    },
  },

  hauck: {
    "pos-promotion": {
      agenda: ReactLazyLoading(() => import("../../customers/hauk/contacts/Agenda")),
    },
    neukundengewinnung: {
      project: ReactLazyLoading(() => import("../../customers/hauk/contacts/show/projects/Neukundengewinnung")),
    },
  },

  default: {
    default: {
      "histories/todos": ReactLazyLoading(() => import("./show/Histories/Todos")),
      "histories/customerContacts": ReactLazyLoading(() => import("./show/Histories/Tasks")),
      "histories/mails": ReactLazyLoading(() => import("./show/Histories/Mails")),
      list: ReactLazyLoading(() => import("./List")),
      new: ReactLazyLoading(() => import("./New")),
      show: ReactLazyLoading(() => import("./show")),
      agenda: ReactLazyLoading(() => import("./Agenda")),
    },
  },
};

export function getComponent(
  session: SessionInterface,
  type: "histories/todos",
): React.ComponentType<{ contact: ContactInterface; status: TodoStatusType }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "histories/customerContacts",
): React.ComponentType<{ contact: ContactInterface }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "histories/mails",
): React.ComponentType<{ contact: ContactInterface }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "project",
): QuestionnaireComponentType | { type: QuestionnaireComponentType; component: QuestionnaireComponentType } | undefined;

export function getComponent(
  session: SessionInterface,
  type: "status-modal/addfields",
): React.ComponentType<{ contact: Nullable<ContactInterface>; states: ContactStatusInterface[] }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "list" | "new" | "show" | "agenda" | "histories/todos" | "searchbar" | "export",
): React.ComponentType | undefined;

export function getComponent(
  session: SessionInterface,
  type: "histories/callback",
): (session: SessionInterface, contact: ContactInterface, history: HistoryInterface) => Promise<void> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "saveTask",
): (
  session: SessionInterface,
  existingTask: Nilable<TaskInterface>,
  states: ContactStatusInterface[],
  values: any,
  t: TFunction,
) => Promise<TaskInterface | undefined> | undefined;

export function getComponent({ currentCustomer, currentProject }: SessionInterface, type: ComponentType) {
  return (
    getIn(COMPONENTS, [currentCustomer.identifier, currentProject.identifier, type]) ||
    getIn(COMPONENTS, [currentCustomer.identifier, "default", type]) ||
    getIn(COMPONENTS, ["default", "default", type])
  );
}
