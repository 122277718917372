import { differenceInDays } from "date-fns";

import type { SessionInterface } from "../store/sessionReducer";
import type {
  ChangeRequestType,
  CoachingType,
  CompanyNewsType,
  ContractInterface,
  EmployeeCouponType,
  EmployeeInterface,
  PermissionType,
  PersonalFileInterface,
  PersonalFileTypeType,
  PresettlementInterface,
  ResidualLeaveInterface,
  SettlementInterface,
  UserInterface,
  VacationRequestInterface,
  WorktimeChangeRequestType,
  WorktimeInterface,
} from "../types";
import type { StaffHistoryInterface } from "../types/staffHistories";
import { parsedDate } from "../utils/dates";
import { may } from "./";
import {
  featureEnabled,
  isAdmin,
  isRoot,
  isStaffAdmin,
  projectFeatureEnabled,
  userAndTargetAreAdmin,
  userFeatureEnabled,
} from "./helpers";

export interface StaffMayInterface {
  // staff/worktimes
  (session: SessionInterface, path: "staff/worktimes"): boolean;
  (session: SessionInterface, path: "staff/worktimes", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "staff/worktimes",
    action: "show" | "edit" | "delete",
    wt: WorktimeInterface,
  ): boolean;

  // staff/worktime-change-requests
  (session: SessionInterface, path: "staff/worktime-change-requests"): boolean;
  (session: SessionInterface, path: "staff/worktime-change-requests", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "staff/worktime-change-requests",
    action: "show" | "edit" | "delete" | "approve" | "reject" | "request",
    request: WorktimeChangeRequestType,
  ): boolean;

  // staff/timesheets
  (session: SessionInterface, path: "staff/timesheets"): boolean;
  (session: SessionInterface, path: "staff/timesheets", action: "show", user: UserInterface): boolean;

  // staff/employees
  (session: SessionInterface, path: "staff/employees"): boolean;
  (session: SessionInterface, path: "staff/employees", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "staff/employees",
    action:
      | "show"
      | "edit"
      | "delete"
      | "contracts"
      | "files"
      | "internal"
      | "settlements"
      | "residualLeaves"
      | "send"
      | "address"
      | "sensitiveProfileInfos"
      | "sensitiveEmployeeInfos",
    employee: EmployeeInterface,
  ): boolean;

  // staff/file-types
  (session: SessionInterface, path: "staff/file-types"): boolean;
  (session: SessionInterface, path: "staff/file-types", action: "new" | "missing"): boolean;
  (session: SessionInterface, path: "staff/file-types", action: "edit" | "delete", type: PersonalFileTypeType): boolean;

  // staff/file-types
  (
    session: SessionInterface,
    path: "staff/contracts",
    action: "edit" | "delete" | "genContract" | "genContractAdaption",
    contrac: ContractInterface,
  ): boolean;

  // staff/histories
  (session: SessionInterface, path: "staff/histories"): boolean;
  (session: SessionInterface, path: "staff/histories", action: "new", employee: EmployeeInterface): boolean;
  (
    session: SessionInterface,
    path: "staff/histories",
    action: "edit" | "delete",
    history: StaffHistoryInterface,
  ): boolean;

  // staff/files
  (
    session: SessionInterface,
    path: "staff/files",
    action: "edit" | "delete" | "send" | "sendExchange" | "signature",
    file: PersonalFileInterface,
  ): boolean;

  // staff/vacation_requests
  (session: SessionInterface, path: "staff/vacation_requests"): boolean;
  (session: SessionInterface, path: "staff/vacation_requests", action: "new" | "plan" | "overview"): boolean;
  (
    session: SessionInterface,
    path: "staff/vacation_requests",
    action: "edit" | "delete" | "request" | "finalize",
    request: VacationRequestInterface,
  ): boolean;

  // staff/presettlements
  (session: SessionInterface, path: "staff/presettlements"): boolean;
  (session: SessionInterface, path: "staff/presettlements", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "staff/presettlements",
    action: "show" | "edit" | "delete",
    presettlement: PresettlementInterface,
  ): boolean;

  // staff/settlements
  (session: SessionInterface, path: "staff/settlements"): boolean;
  (session: SessionInterface, path: "staff/settlements", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "staff/settlements",
    action: "edit" | "delete" | "download" | "finalize",
    settlement: SettlementInterface,
  ): boolean;

  // staff/residual_leaves
  (
    session: SessionInterface,
    path: "staff/residual_leaves",
    action: "edit" | "delete",
    leave: ResidualLeaveInterface,
  ): boolean;

  // staff/company-news
  (session: SessionInterface, path: "staff/company-news"): boolean;
  (session: SessionInterface, path: "staff/company-news", action: "new"): boolean;
  (session: SessionInterface, path: "staff/company-news", action: "edit" | "delete", news: CompanyNewsType): boolean;

  // staff/coachings
  (session: SessionInterface, path: "staff/coachings"): boolean;
  (session: SessionInterface, path: "staff/coachings", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "staff/coachings",
    action: "edit" | "show" | "delete" | "confirm",
    coaching: CoachingType,
  ): boolean;

  // staff/coupons
  (session: SessionInterface, path: "staff/coupons"): boolean;
  (session: SessionInterface, path: "staff/coupons", action: "new"): boolean;
  (session: SessionInterface, path: "staff/coupons", action: "edit" | "delete", coupon: EmployeeCouponType): boolean;

  // staff/performances
  (session: SessionInterface, path: "staff/performance"): boolean;
  (session: SessionInterface, path: "staff/performance", action: "show", user: UserInterface): boolean;

  // staff
  (session: SessionInterface, path: "staff"): boolean;
  (session: SessionInterface, path: "staff", action: "supervisor"): boolean;

  // change requests
  (session: SessionInterface, path: "staff/change-requests"): boolean;
  (session: SessionInterface, path: "staff/change-requests", action: "new" | "send"): boolean;
  (
    session: SessionInterface,
    path: "staff/change-requests",
    action: "edit" | "show" | "delete" | "request" | "return" | "reject" | "approve" | "create-user",
    coaching: ChangeRequestType,
  ): boolean;
}

const WORKTIME_PERMISSIONS: PermissionType<WorktimeInterface> = {
  "staff/worktimes": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "worktimes"),
    edit: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || (currentUser.teamLeader && resource.userId !== currentUser.id)),
    delete: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || (currentUser.teamLeader && resource.userId !== currentUser.id)),
    new: ({ currentCustomer, currentUser }) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && (isRoot(currentUser) || currentUser.teamLeader),
  },
};

export const WORKTIME_CHANGE_REQUEST_PERMISSION: PermissionType<WorktimeChangeRequestType> = {
  "staff/worktime-change-requests": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "worktimes"),
    delete: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) ||
        isStaffAdmin(currentUser) ||
        currentUser.teamLeader ||
        currentUser.id === resource.userId) &&
      !["APPROVED", "REJECTED"].includes(resource.status),
    edit: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) ||
        isStaffAdmin(currentUser) ||
        currentUser.teamLeader ||
        currentUser.id === resource.userId) &&
      !["APPROVED", "REJECTED"].includes(resource.status),
    approve: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || isStaffAdmin(currentUser) || currentUser.teamLeader) &&
      !["APPROVED", "REJECTED"].includes(resource.status),
    reject: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || isStaffAdmin(currentUser) || currentUser.teamLeader) &&
      !["APPROVED", "REJECTED"].includes(resource.status),
    request: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || isStaffAdmin(currentUser) || currentUser.teamLeader) &&
      !["APPROVED", "REJECTED"].includes(resource.status),
  },
};

const TIMESHEET_PERMISSIONS: PermissionType<UserInterface> = {
  "staff/timesheets": {
    index: ({ currentUser, currentCustomer }) => featureEnabled(currentUser, currentCustomer, "worktimes"),
    show: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || resource.id === currentUser.id),
  },
};

const EMPLOYEE_PERMISSIONS: PermissionType<EmployeeInterface> = {
  "staff/employees": {
    index: ({ currentUser, currentCustomer }, _resource) => featureEnabled(currentUser, currentCustomer, "worktimes"),
    delete: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
    new: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    edit: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    send: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    contracts: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      ((isStaffAdmin(currentUser) && !userAndTargetAreAdmin(currentUser, resource.user)) ||
        currentUser.id === resource.userId),
    files: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isStaffAdmin(currentUser) || currentUser.id === resource.userId),
    internal: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      ((isStaffAdmin(currentUser) && !userAndTargetAreAdmin(currentUser, resource.user)) ||
        currentUser.id === resource.userId),
    settlements: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      ((isStaffAdmin(currentUser) && !userAndTargetAreAdmin(currentUser, resource.user)) ||
        currentUser.id === resource.userId),
    sensitiveProfileInfos: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || currentUser.id === resource.userId),
    sensitiveEmployeeInfos: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || currentUser.id === resource.userId),
    address: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isAdmin(currentUser) || currentUser.id === resource.userId),
  },
};

const FILE_TYPES_PERMISSIONS: PermissionType<PersonalFileTypeType> = {
  "staff/file-types": {
    index: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
  },
};

const CONTRACTS_PERMISSIONS: PermissionType<ContractInterface> = {
  "staff/contracts": {
    edit: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    delete: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
    genContract: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    genContractAdaption: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
  },
};

const STAFF_HISTORIES_PERMISSIONS: PermissionType<StaffHistoryInterface> = {
  "staff/histories": {
    index: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isAdmin(currentUser),
    new: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isAdmin(currentUser),
    edit: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isAdmin(currentUser),
    delete: ({ currentUser, currentCustomer }) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
  },
};

const FILES_PERMISSIONS: PermissionType<PersonalFileInterface> = {
  "staff/files": {
    edit: ({ currentUser, currentCustomer }, _file) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
    send: ({ currentUser, currentCustomer }, file) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isRoot(currentUser) &&
      file.fileType?.category === "contract",
    sendExchange: ({ currentUser, currentCustomer }, _file) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
    signature: ({ currentUser }, file) => isRoot(currentUser) && file.fileType?.category === "contract_adaption",
    delete: ({ currentUser, currentCustomer }, _file) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
  },
};

const VACATION_PERMISSIONS: PermissionType<VacationRequestInterface> = {
  "staff/vacation_requests": {
    index: ({ currentUser, currentCustomer }, resource) => featureEnabled(currentUser, currentCustomer, "worktimes"),
    delete: ({ currentUser, currentCustomer }, request) =>
      ["AWAITING", "QUERY"].includes(request.status) &&
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isRoot(currentUser),
    edit: ({ currentUser, currentCustomer }, request) =>
      ["AWAITING", "QUERY"].includes(request.status) &&
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (request.userId === currentUser.id || isRoot(currentUser)),
    request: ({ currentUser, currentCustomer }, request) =>
      ["AWAITING", "QUERY"].includes(request.status) && featureEnabled(currentUser, currentCustomer, "worktimes"),
    finalize: ({ currentUser, currentCustomer }, request) =>
      ["AWAITING", "QUERY"].includes(request.status) &&
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isRoot(currentUser) || request.userId !== currentUser.id),
    plan: ({ currentUser, currentCustomer }, _) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isStaffAdmin(currentUser) || currentUser.teamLeader),
  },
};

const PRESETTLEMENT_PERMISSIONS: PermissionType<PresettlementInterface> = {
  "staff/presettlements": {
    index: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
  },
};

const MAX_EDITABLE_DAYS = 7;

const SETTLEMENT_PERMISSIONS: PermissionType<SettlementInterface> = {
  "staff/settlements": {
    index: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
    edit: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isRoot(currentUser) &&
      (!resource.isVisible || differenceInDays(new Date(), parsedDate(resource.insertedAt)) <= MAX_EDITABLE_DAYS),
    delete: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isRoot(currentUser) &&
      (!resource.isVisible || differenceInDays(new Date(), parsedDate(resource.insertedAt)) <= MAX_EDITABLE_DAYS),
    finalize: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isRoot(currentUser) &&
      (!resource.isVisible || differenceInDays(new Date(), parsedDate(resource.insertedAt)) <= MAX_EDITABLE_DAYS),
  },
};

const RESIDUAL_LEAVES_PERMISSIONS: PermissionType<ResidualLeaveInterface> = {
  "staff/residual_leaves": {
    edit: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
    delete: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isRoot(currentUser),
  },
};

const COMPANY_NEWS_PREMISSIONS: PermissionType<CompanyNewsType> = {
  "staff/company-news": {
    index: ({ currentUser, currentCustomer }, resource) => featureEnabled(currentUser, currentCustomer, "company_news"),
    new: ({ currentUser }, resource) => isStaffAdmin(currentUser),
    edit: ({ currentUser }, resource) => isRoot(currentUser),
  },
};

const COACHING_PREMISSIONS: PermissionType<CoachingType> = {
  "staff/coachings": {
    index: ({ currentUser, currentCustomer }, resource) =>
      (isRoot(currentUser) && projectFeatureEnabled(currentCustomer, "coachings")) ||
      featureEnabled(currentUser, currentCustomer, "coachings"),
    new: ({ currentUser, currentCustomer }, resource) =>
      (isRoot(currentUser) && projectFeatureEnabled(currentCustomer, "coachings")) ||
      (featureEnabled(currentUser, currentCustomer, "coachings") && currentUser.teamLeader),
    edit: ({ currentCustomer, currentUser }, resource) =>
      (isRoot(currentUser) && projectFeatureEnabled(currentCustomer, "coachings")) ||
      (featureEnabled(currentUser, currentCustomer, "coachings") && currentUser.teamLeader),
    show: ({ currentCustomer, currentUser }, resource) =>
      (isRoot(currentUser) && projectFeatureEnabled(currentCustomer, "coachings")) ||
      (featureEnabled(currentUser, currentCustomer, "coachings") &&
        (currentUser.teamLeader || resource.user.id === currentUser.id)),
    confirm: ({ currentCustomer, currentUser }, resource) =>
      featureEnabled(currentUser, currentCustomer, "coachings") &&
      !resource.signed &&
      resource.user.id === currentUser.id,
    delete: ({ currentCustomer, currentUser }, resource) =>
      (isRoot(currentUser) && projectFeatureEnabled(currentCustomer, "coachings")) ||
      (featureEnabled(currentUser, currentCustomer, "coachings") && currentUser.teamLeader),
  },
};

const COUPON_PERMISSIONS: PermissionType<EmployeeCouponType> = {
  "staff/coupons": {
    index: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    new: ({ currentUser }, _resource) => isStaffAdmin(currentUser),
    edit: ({ currentUser }, _resource) => isStaffAdmin(currentUser),
    delete: ({ currentUser }, _resource) => isStaffAdmin(currentUser),
  },
};

const PERFORMANCE_PERMISSIONS: PermissionType<UserInterface> = {
  "staff/performance": {
    index: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    show: ({ currentUser, currentCustomer }, user) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (isStaffAdmin(currentUser) || currentUser.id === user.id || currentUser.teamLeader),
  },
};

const CHANGE_REQUEST_PERMISSIONS: PermissionType<ChangeRequestType> = {
  "staff/change-requests": {
    index: ({ currentUser, currentCustomer }, _resource) => featureEnabled(currentUser, currentCustomer, "worktimes"),
    show: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      (resource.employee?.userId === currentUser.id || isStaffAdmin(currentUser)),
    return: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      currentUser.id === resource.employee?.userId &&
      resource.status === "REQUEST",
    request: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isStaffAdmin(currentUser) &&
      !["APPROVED", "REJECTED", "REQUEST"].includes(resource.status) &&
      !!resource.employeeId,
    edit: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isStaffAdmin(currentUser) &&
      !["APPROVED", "REJECTED"].includes(resource.status),
    delete: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
    reject: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isStaffAdmin(currentUser) &&
      !["APPROVED", "REJECTED"].includes(resource.status) &&
      !!resource.employeeId,
    approve: ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isStaffAdmin(currentUser) &&
      !["APPROVED", "REJECTED"].includes(resource.status) &&
      !!resource.employeeId,
    "create-user": ({ currentUser, currentCustomer }, resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      isRoot(currentUser) &&
      !["APPROVED", "REJECTED"].includes(resource.status) &&
      !resource.employeeId,
    send: ({ currentUser, currentCustomer }, _resource) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") && isStaffAdmin(currentUser),
  },
};

const STAFF_PERMISSIONS: PermissionType<null> = {
  staff: {
    index: (session) =>
      may(session, "staff/worktimes") ||
      may(session, "staff/timesheets") ||
      may(session, "staff/employees") ||
      may(session, "staff/file-types") ||
      may(session, "staff/vacation_requests") ||
      may(session, "staff/presettlements") ||
      may(session, "staff/settlements") ||
      may(session, "staff/company-news") ||
      may(session, "staff/coachings") ||
      may(session, "staff/coupons") ||
      may(session, "staff/performance") ||
      may(session, "staff/change-requests"),

    supervisor: ({ currentCustomer, currentUser }) =>
      featureEnabled(currentUser, currentCustomer, "worktimes") &&
      userFeatureEnabled(currentUser, "worktimes/supervisor"),
  },
};

const StaffPermissions = {
  ...WORKTIME_PERMISSIONS,
  ...WORKTIME_CHANGE_REQUEST_PERMISSION,
  ...TIMESHEET_PERMISSIONS,
  ...EMPLOYEE_PERMISSIONS,
  ...FILE_TYPES_PERMISSIONS,
  ...CONTRACTS_PERMISSIONS,
  ...STAFF_HISTORIES_PERMISSIONS,
  ...FILES_PERMISSIONS,
  ...VACATION_PERMISSIONS,
  ...PRESETTLEMENT_PERMISSIONS,
  ...SETTLEMENT_PERMISSIONS,
  ...RESIDUAL_LEAVES_PERMISSIONS,
  ...STAFF_PERMISSIONS,
  ...COMPANY_NEWS_PREMISSIONS,
  ...COACHING_PREMISSIONS,
  ...COUPON_PERMISSIONS,
  ...PERFORMANCE_PERMISSIONS,
  ...CHANGE_REQUEST_PERMISSIONS,
};

export default StaffPermissions;
