import { addHours } from "date-fns";
import type { TFunction } from "i18next";
import _ from "lodash";

import { EVENT_MUTATION, EVENT_TYPES_LIST_QUERY } from "../../../../api/calendar";
import { CONTACT_MUTATION } from "../../../../api/contacts/contacts";
import { TASK_MUTATION } from "../../../../api/tasks";
import apolloClient from "../../../../apollo";
import { addDangerFlash, addSuccessFlash } from "../../../../components/flash/flashReducer";
import handleError, { MutationError } from "../../../../handleError";
import store from "../../../../store";
import { SessionInterface } from "../../../../store/sessionReducer";
import {
  ContactMutationInterface,
  ContactStatusInterface,
  EventMutationInterface,
  EventMutationVariablesInterface,
  EventTypesDataInterface,
  EventTypesVariablesInterface,
  Nilable,
  TaskInterface,
  TaskMutationInterface,
} from "../../../../types";
import { naiveDateTime } from "../../../../utils/dates";
import type { ValuesType } from "../types";

function determineEventType(type: string, values: ValuesType) {
  if (type === "Wiedervorlage") {
    return "FOLLOWUP";
  }
  return values.attrs.appointmentChannel === "Telefon-Termin" ? "PHONE_EVENT" : "EVENT";
}

async function createEvent(
  session: SessionInterface,
  type: "Wiedervorlage" | "Termin",
  values: ValuesType,
  t: TFunction,
) {
  try {
    const { data: eventTypeData } = await apolloClient.query<EventTypesDataInterface, EventTypesVariablesInterface>({
      query: EVENT_TYPES_LIST_QUERY,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
      },
    });

    const eType = determineEventType(type, values);
    const eventType = eventTypeData?.eventTypes.find((eventType) => eventType.type === eType);

    if (!eventType) {
      store.dispatch(addDangerFlash(`Oops! Es konnte kein ${eType}-Event-Typ gefunden werden!`));
    }

    const startTime =
      type === "Wiedervorlage" ? naiveDateTime(values.attrs.followUp) : naiveDateTime(values.attrs.arrangedAppointment);

    const endTime =
      type === "Wiedervorlage"
        ? naiveDateTime(values.attrs.followUp)
        : naiveDateTime(addHours(values.attrs.arrangedAppointment!, 0.5));

    const { data } = await apolloClient.mutate<EventMutationInterface, EventMutationVariablesInterface>({
      mutation: EVENT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        event: {
          subject: type === "Wiedervorlage" ? type : values.attrs.appointmentChannel,
          allDay: false,
          startTime,
          endTime,
          contactId: values.contactId,
          ownerId: type === "Wiedervorlage" ? session.currentUser.id : values.attrs.salesEmployeeId,
          typeId: eventType?.id,
          attrs: {
            teamsUrl: values.attrs.teamsUrl,
            contactPersonFirstname: values.attrs.contactPersonFirstname,
            contactPersonLastname: values.attrs.contactPersonLastname,
            meetingMail: values.attrs.contactPersonMail,
          },
        },
      },
    });

    if (!data?.mutateEvent) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

async function mutateContactChild(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data } = await apolloClient.mutate<ContactMutationInterface>({
      mutation: CONTACT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: !values.attrs.contactPersonId ? null : values.attrs.contactPersonId,
        contact: {
          ctype: "SUB",
          parentId: values.contactId,
          isCompany: false,
          salutation: values.attrs.contactPersonSalutation,
          firstname: values.attrs.contactPersonFirstname,
          lastname: values.attrs.contactPersonLastname,
          position: values.attrs.contactPersonPosition,
          infos: [
            { itype: "WORK_PHONE", value: values.attrs.contactPersonPhone },
            { itype: "EMAIL", value: values.attrs.contactPersonMail },
          ].filter((v) => !!v.value),
          attrs: {
            addfields: {
              companyId: values.attrs.companyId,
            },
          },
        },
      },
    });

    if (!data?.mutateContact) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

export const saveTask = async (
  session: SessionInterface,
  existingTask: Nilable<TaskInterface>,
  states: ContactStatusInterface[],
  values: ValuesType,
  t: TFunction,
) => {
  const sortingResultsToSave = [
    "Firma nicht geeignet, da öffentliche Institution",
    "Firma nicht geeignet aufgrund der Firmenart",
    "Dublette",
  ];

  const acceptNRValueSavingByForm = {
    notReachedReason:
      values.contactType === "Outbound" &&
      values.attrs.reached === "nicht erreicht" &&
      values.attrs.notReachedReason === "Kein telefonischer Kontakt möglich aufgrund von Servicehotline",
    companyClosedReason:
      values.contactType === "Outbound" &&
      values.attrs.reached === "nicht erreicht" &&
      ["klingelt wiederholt durch (mind. 3x)", "Rufnummer nicht vergeben"].includes(values.attrs.notReachedReason),
    dataSortingResult:
      values.contactType === "Datensortierung" && sortingResultsToSave.includes(values.attrs.dataSortingResult),
  };

  if (acceptNRValueSavingByForm["notReachedReason"]) {
    values.caddfields.notReachedReason = values.attrs.notReachedReason;
  }

  if (acceptNRValueSavingByForm["companyClosedReason"]) {
    values.caddfields.notReachedReason = values.attrs.companyClosedReason;
  }

  if (acceptNRValueSavingByForm["dataSortingResult"]) {
    values.caddfields.notReachedReason = values.attrs.dataSortingResult;
  }

  if (
    !acceptNRValueSavingByForm["notReachedReason"] &&
    !acceptNRValueSavingByForm["companyClosedReason"] &&
    !acceptNRValueSavingByForm["dataSortingResult"]
  ) {
    values.caddfields.notReachedReason = "";
  }

  const acceptNoInterestReason =
    (values.attrs.reached === "erreicht" &&
      values.attrs.reachedPerson === "Ansprechpartner" &&
      values.attrs.result === "kein Interesse") ||
    (values.attrs.emailContactType === "beantwortet" &&
      ((values.attrs.contactResult === "Terminstornierung" &&
        values.attrs.cancellationAction === "kein neuer Termin gewünscht" &&
        values.attrs.noNewAppointmentReasonKnown === "Begründung bekannt") ||
        values.attrs.contactResult === "Infomail - kein Interesse"));

  if (!acceptNoInterestReason) {
    values.caddfields.noInterestReason = "";
  }

  const acceptCancellationReason = values.attrs.inboundConcern === "Terminstornierung";

  if (!acceptCancellationReason) {
    values.caddfields.cancellationReason = "";
  }

  const appointmentBooked = values.attrs.result === "Termin";

  if (appointmentBooked) {
    createEvent(session, "Termin", values, t);
  }

  if (!!values.attrs.contactPersonFirstname || !!values.attrs.contactPersonLastname) {
    await mutateContactChild(session, values, t);
  }

  if (values.attrs.followUp) {
    await createEvent(session, "Wiedervorlage", values, t);
  }

  const { statusId, caddfields, contact } = values;

  try {
    const { data } = await apolloClient.mutate<TaskMutationInterface>({
      mutation: TASK_MUTATION,
      variables: {
        values: _.omit(values, ["infos", "contact", "statusId", "caddfields"]),
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: existingTask?.id,
      },
    });

    if (!data?.mutateTask) {
      throw new MutationError();
    }

    if ((statusId || caddfields) && contact) {
      const status = states.find((s) => s.id === statusId);

      const cnt: Record<string, any> = {};
      cnt.attrs = {
        ...contact.attrs,
        addfields: { ...contact.attrs.addfields, ...caddfields },
      };

      const { data } = await apolloClient.mutate<ContactMutationInterface>({
        mutation: CONTACT_MUTATION,
        variables: {
          customerId: session.currentCustomer.id,
          projectId: session.currentProject.id,
          id: contact.id,
          contact: { ...(cnt || {}), statusId: status?.id },
        },
      });

      if (!data?.mutateContact) {
        throw new MutationError();
      }
    }

    const transString = !!existingTask?.id ? "customerContacts:edit_form.updated" : "customerContacts:new_form.created";

    store.dispatch(addSuccessFlash(t(transString)));

    return data.mutateTask;
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
};
