import type { TFunction } from "i18next";
import _ from "lodash";

import { EVENT_MUTATION, EVENT_TYPES_LIST_QUERY } from "../../../api/calendar";
import { TASK_MUTATION } from "../../../api/tasks";
import { TODO_MUTATION } from "../../../api/todos";
import apolloClient from "../../../apollo";
import { addDangerFlash, addSuccessFlash } from "../../../components/flash/flashReducer";
import handleError, { MutationError } from "../../../handleError";
import store from "../../../store";
import { SessionInterface } from "../../../store/sessionReducer";
import {
  ContactStatusInterface,
  EventMutationInterface,
  EventMutationVariablesInterface,
  EventTypesDataInterface,
  EventTypesVariablesInterface,
  Nilable,
  TaskInterface,
  TaskMutationInterface,
  TodoMutationInterface,
} from "../../../types";
import { naiveDateTime } from "../../../utils/dates";
import { ValuesType } from "./types";

async function createFollowUpEvent(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data: eventTypeData } = await apolloClient.query<EventTypesDataInterface, EventTypesVariablesInterface>({
      query: EVENT_TYPES_LIST_QUERY,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
      },
    });

    const eventType = eventTypeData?.eventTypes.find((eventType) => eventType.type === "FOLLOWUP");

    if (!eventType) {
      store.dispatch(addDangerFlash("Oops! Es konnte kein FOLLOWUP-Event-Typ gefunden werden!"));
    }

    const { data } = await apolloClient.mutate<EventMutationInterface, EventMutationVariablesInterface>({
      mutation: EVENT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        event: {
          subject: "Wiedervorlage",
          allDay: false,
          startTime: naiveDateTime(values.attrs.followUp),
          endTime: naiveDateTime(values.attrs.followUp),
          contactId: values.contactId,
          ownerId: session.currentUser.id,
          typeId: eventType?.id,
        },
      },
    });

    if (!data?.mutateEvent) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

async function createTodo(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data } = await apolloClient.mutate<TodoMutationInterface>({
      mutation: TODO_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        values: {
          description: values.todo.description,
          contactId: values.contactId,
          ownerId: values.todo.ownerId,
        },
      },
    });

    if (!data?.mutateTodo) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

export const saveTask = async (
  session: SessionInterface,
  _existingTask: Nilable<TaskInterface>,
  _states: ContactStatusInterface[],
  values: ValuesType,
  t: TFunction,
) => {
  if (values.attrs.followUp) {
    createFollowUpEvent(session, values, t);
  }

  if (values.reactionNeeded) {
    createTodo(session, values, t);
  }

  try {
    const { data } = await apolloClient.mutate<TaskMutationInterface>({
      mutation: TASK_MUTATION,
      variables: {
        values: _.omit(values, ["infos", "contact", "statusId", "caddfields", "todo"]),
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
      },
    });

    if (!data?.mutateTask) {
      throw new MutationError();
    }

    store.dispatch(addSuccessFlash(t("customerContacts:new_form.created")));

    return data.mutateTask;
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
};
